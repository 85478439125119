import { ProductFeatureModelDto } from '@treasury/api/bo';
import { DomainEntity } from '../../../../shared';
import { FeatureApiType } from '../types/product-feature.enum';

export class CompanyProductFeature extends DomainEntity<ProductFeatureModelDto> {
    protected dto: ProductFeatureModelDto = this.dto;

    public createDefault(): this {
        throw new Error('createDefault not implemented');
    }

    public get id() {
        return this.dto.id;
    }

    public get permissions() {
        return this.dto.permissions;
    }

    public get status() {
        return this.dto.status;
    }

    public set status(value: string | undefined) {
        this.dto.status = value;
    }

    public get accountIds() {
        return this.dto.accountIds ?? [];
    }

    public set accountIds(values: number[]) {
        this.dto.accountIds = values;
    }

    public get isConfigurable() {
        return this.dto.isConfigurable;
    }

    public get name() {
        return this.dto.name ?? '';
    }

    public get type() {
        return this.dto.type as FeatureApiType;
    }

    public isAccountAllowed(accountId: number) {
        return this.dto.accountIds?.includes(accountId) ?? false;
    }
}
