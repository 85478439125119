import { UserInformationModelDto, UserSummaryModelDto } from '@treasury/api/bo';
import { clone } from '@treasury/utils';
import { DomainEntity } from '../../../../shared';

const nullCompanyUser: UserInformationModelDto = {
    userId: 0,
    name: '',
    loginId: '',
    department: '',
    email: '',
    phone: '',
    extension: '',
    fax: '',
    isMfaBlocked: false,
    isSuperUser: false,
    isAccountMaskingConfigurable: false,
    isMethodOfVerificationConfigurable: false,
    isAdmin: false,
    copyUserId: 0,
    isEnrolled: false,
    isActive: false,
    hasLoggedIn: false,
    success: false,
};

export class CompanyUserInformation extends DomainEntity<UserInformationModelDto> {
    constructor(dto = clone(nullCompanyUser)) {
        super(dto);
    }

    public createDefault(): this {
        return new CompanyUserInformation(clone(nullCompanyUser)) as this;
    }

    public get loginId() {
        return this.dto.loginId;
    }

    public get name() {
        return this.dto.name;
    }

    public get rsaId() {
        return this.dto.mfaId;
    }

    public get isActive() {
        return this.dto.isActive;
    }

    public get isSuperUser() {
        return this.dto.isSuperUser;
    }

    public get isAdmin() {
        return this.dto.isAdmin;
    }

    public get email() {
        return this.dto.email;
    }

    public get phone() {
        return this.dto.phone;
    }

    public get fax() {
        return this.dto.fax;
    }
}
